<template>
    <div class="min-h-screen flex flex-col">
        <div class="flex-grow">
            <slot></slot>
        </div>
        <footer class="bg-gray-50 w-full py-5 px-4 sm:px-8">
            <div class="flex flex-col sm:flex-row justify-between items-center gap-4">
                <div class="flex flex-wrap justify-center sm:justify-start text-sm text-gray-400 gap-2">
                    <a class="hover:text-gray-800" href="https://wobby.ai?utm_source=app" target="_blank">© Copyright Wobby BV {{ date }}</a>
                    <span class="hidden sm:inline">|</span>
                    <a class="hover:text-gray-800" href="https://community.wobby.ai/" target="_blank">Community</a>
                    <a class="hover:text-gray-800" href="https://help.wobby.ai" target="_blank">Guide</a>
                    <a class="hover:text-gray-800" href="https://wobby.ai/contact/" target="_blank">Contact us</a>
                </div>
                <div class="flex text-sm text-gray-400 gap-2">
                    <a class="hover:text-gray-800" href="https://www.wobby.ai/terms-of-use" target="_blank">Terms of Use</a>
                    <a class="hover:text-gray-800" href="https://wobby.ai/privacy-policy/" target="_blank">Privacy Policy</a>
                </div>
            </div>
        </footer>
    </div>
</template>

<script setup lang="ts">
import { useBreakpoints, breakpointsTailwind } from '@vueuse/core';

const breakpoints = useBreakpoints(breakpointsTailwind)
let isMobile = computed(() => {
    return breakpoints.isSmallerOrEqual('sm')
})


const date = new Date().getFullYear();
</script>